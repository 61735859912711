import React, { useState, ChangeEvent } from 'react';

import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';

import styles from './styles.module.scss';

const Form = () => {
  const [step, setStep] = useState(4);
  const [complete, setComplete] = useState(Array);
  const [formValues, setFormValues] = useState({});

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      setFormValues({...formValues, [e.target.name]: e.target.value});
    } else {
      console.log('no target found');
    }
  }

  const handleNext = () => {
    // TODO: check form is complete
    const arr = complete;
    arr.push(step);
    setComplete(arr);
    setStep(step + 1);
  }

  const handleStepChange = (step: number) => {
    if (complete.includes(step)){
      setStep(step);
    }
  }

  const renderStep = () => {
    switch(step) {
      case 1:
        return <Step1 handleChange={handleChange} handleNext={handleNext} formValues={formValues} />
      case 2:
        return <Step2 handleChange={handleChange} handleNext={handleNext} formValues={formValues} />
      case 3:
        return <Step3 handleChange={handleChange} handleNext={handleNext} formValues={formValues} />
      case 4:
        return <Step4 handleChange={handleChange} handleNext={handleNext} formValues={formValues} />
      default:
        return "Something went wrong"
    }
  }

  const isActive = (step: number) => step === step

  return (
    <div className={styles.formContainer}>
      <div className={styles.list}>
        <div className={styles.option} data-active={isActive(1)} onClick={() => handleStepChange(1)}>
          <p>Step 1</p>
          <h3>Family</h3>
        </div>
        <div className={styles.option} data-active={isActive(2)}onClick={() => handleStepChange(2)}>
          <p>Step 2</p>
          <h3>Child</h3>
        </div>
        <div className={styles.option} data-active={isActive(3)}onClick={() => handleStepChange(3)}>
          <p>Step 3</p>
          <h3>Additional Information</h3>
        </div>
        <div className={styles.option} data-active={isActive(4)}onClick={() => handleStepChange(4)}>
          <p>Step 4</p>
          <h3>Agreement</h3>
        </div>
      </div>
      <div className={styles.stepContainer}>
        {renderStep()}
      </div>
    </div>
  )
}

export default Form;
