import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import Arrow from 'images/arrow.svg';

import styles from '../styles.module.scss';

interface Props {
  handleChange: Function;
  handleNext: Function;
  formValues: any;
}

const Step1 = ({ handleChange, handleNext, formValues }: Props) => {
  const [parent1, toggleParent1] = useState(true);
  const [parent2, toggleParent2] = useState(false);

  const isActive = (parent: boolean) => parent ? 'auto' : 0

  return (
    <div>
      <form onSubmit={() => handleNext()}>
      <div className={styles.inputContainer}>
        <h3>Family Enrolement</h3>
        <button type="button" data-active={parent1} onClick={() => toggleParent1(!parent1)}><h4>Parent/Carer 1</h4><img src={Arrow} /></button>
        <AnimateHeight height={isActive(parent1)} className={styles.fullWidth}>
          <div className={styles.inner}>
            <label>
              Full name
              <input
                name="parent1FullName"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1FullName || ''}
                required
              />
            </label>
            <label>
              Phone
              <input
                name="parent1Phone"
                type="number"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1Phone || ''}
                required
              />
            </label>
            <label>
              Email
              <input
                name="parent1Email"
                type="email"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1Email || ''}
                required
              />
            </label>
            <label>
              Customer reference number
              <input
                name="parent1CRN"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1CRN || ''}
              />
            </label>
            <label>
              Relationship to child
              <input
                name="parent1Relationship"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1Relationship || ''}
                required
              />
            </label>
            <label>
              Date of birth
              <input
                type="date"
                name="parent1DOB"
                onChange={(e) => handleChange(e)}
                required
              />
            </label>
            <label className={styles.fullWidth}>
              Address
              <input
                name="parent1Address"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1Address|| ''}
                required
              />
            </label>
            <label>
              Does child live with you?
              <div className={styles.radioGroup}>
                <label>
                  Yes
                  <input type="radio" name="parent1LiveWith" value="yes" onChange={(e) => handleChange(e)} required />
                </label>
                <label>
                  No
                  <input type="radio" name="parent1LiveWith" value="no" onChange={(e) => handleChange(e)}  required />
                </label>
                <label>
                  Shared care
                  <input type="radio" name="parent1LiveWith" value="shared" onChange={(e) => handleChange(e)}  required />
                </label>
              </div>
            </label>
            <label>
              Occupation
              <input type="text" name="parent1Occupation" onChange={(e) => handleChange(e)}  required/>
            </label>
            <label>
              Organisation/Employer
              <input type="text" name="parent1Employer" onChange={(e) => handleChange(e)}/>
            </label>
            <label>
              Work Phone
              <input type="number" name="parent1WorkPhone" onChange={(e) => handleChange(e)} required/>
            </label>
            <label>
              Primary spoken language
              <input type="text" name="parent1SpokenLanguage" onChange={(e) => handleChange(e)} required/>
            </label>
            <label>
              Cultural Background
              <input type="text" name="parent1CulturalBackground" onChange={(e) => handleChange(e)} required/>
            </label>
          </div>
        </AnimateHeight>
        <button type="button" data-active={parent2} onClick={() => toggleParent2(!parent2)}><h4>Parent/Carer 2</h4><img src={Arrow} /></button>
        <AnimateHeight height={isActive(parent2)} className={styles.fullWidth}>
          <div className={styles.inner}>
            <label>
              Full name
              <input
                name="parent2FullName"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1FullName || ''}
              />
            </label>
            <label>
              Phone
              <input
                name="parent2Phone"
                type="number"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1Phone || ''}
              />
            </label>
            <label>
              Email
              <input
                name="parent2Email"
                type="email"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1Email || ''}
              />
            </label>
            <label>
              Customer reference number
              <input
                name="parent2CRN"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1CRN || ''}
              />
            </label>
            <label>
              Relationship to child
              <input
                name="parent2Relationship"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1Relationship || ''}
              />
            </label>
            <label>
              Date of birth
              <input
                type="date"
                name="parent2DOB"
                onChange={(e) => handleChange(e)}
              />
            </label>
            <label className={styles.fullWidth}>
              Address
              <input
                name="parent2Address"
                onChange={(e) => handleChange(e)}
                value={formValues && formValues.parent1Address|| ''}
              />
            </label>
            <label>
              Does child live with you?
              <div className={styles.radioGroup}>
                <label>
                  Yes
                  <input type="radio" name="parent2LiveWith" value="yes" onChange={(e) => handleChange(e)} />
                </label>
                <label>
                  No
                  <input type="radio" name="parent2LiveWith" value="no" onChange={(e) => handleChange(e)} />
                </label>
                <label>
                  Shared care
                  <input type="radio" name="parent2LiveWith" value="shared" onChange={(e) => handleChange(e)} />
                </label>
              </div>
            </label>
            <label>
              Occupation
              <input type="text" name="parent2Occupation" onChange={(e) => handleChange(e)}/>
            </label>
            <label>
              Organisation/Employer
              <input type="text" name="parent2Employer" onChange={(e) => handleChange(e)}/>
            </label>
            <label>
              Work Phone
              <input type="number" name="parent2WorkPhone" onChange={(e) => handleChange(e)}/>
            </label>
            <label>
              Primary spoken language
              <input type="text" name="parent2SpokenLanguage" onChange={(e) => handleChange(e)}/>
            </label>
            <label>
              Cultural Background
              <input type="text" name="parent2CulturalBackground" onChange={(e) => handleChange(e)}/>
            </label>
            <label>
              Primary spoken language
              <input type="text" name="parent2SpokenLanguage" onChange={(e) => handleChange(e)}/>
            </label>
            <label>
              Cultural Background
              <input type="text" name="parent2CulturalBackground" onChange={(e) => handleChange(e)}/>
            </label>
          </div>
        </AnimateHeight>
        <button type="submit" className={styles.nextBtn}>Next</button>
      </div>
    </form>
    </div>
  )
}

export default Step1;
